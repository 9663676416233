html, body {
  height: 100%;
  background-image: url("spiration-light.29d7210f.png");
  margin: 0;
}

.c62 {
  height: 62%;
}

.c38 {
  height: 38%;
  min-height: 150px;
  text-align: center;
  background: #fc0;
  font-family: Montserrat, sans-serif;
  position: relative;
}

.c20banner {
  height: 1%;
  width: 100%;
  z-index: 10;
  background: #e8103e;
  position: absolute;
  top: 56%;
  left: 0;
}

.c20text {
  width: 100%;
  position: absolute;
  bottom: 18%;
  left: 0;
}

.blueBackground {
  background: #303b52;
}

.gossipBrewingSuperCentered {
  content: url("GossipBrewing-27-web.e69d0972.png");
  max-width: 80%;
  max-height: 100%;
  z-index: 9;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

/*# sourceMappingURL=index.f5fb6d4b.css.map */
