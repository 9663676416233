html, body {
    height: 100%;
    margin: 0px;
    background-image: url("images/spiration-light.png");
}
.c62 {
    height: 62%;
}
.c38 {
  height: 38%;
  min-height: 150px;
  background: #ffcc00;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  position: relative;
}
.c20banner {
  position: absolute;
  left: 0px;
  top: 56%;
  height: 1%;
  width: 100%;
  background: #e8103e;
  z-index: 10;
}
.c20text {
  position: absolute;
  bottom: 18%;
  left: 0;
  width: 100%;
}
.blueBackground {
  background: #303b52;
}
.gossipBrewingSuperCentered{
  content: url("images/GossipBrewing-27-web.png");
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  max-height: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}